body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main-head {
  height: 150px;
  background: #FFF;

}

.sidenav {
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 20px;
}


.main {
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-form {
    margin-top: 10%;
  }

  .register-form {
    margin-top: 10%;
  }
}

@media screen and (min-width: 768px) {
  .main {
    margin-left: 40%;
  }

  .sidenav {
    width: 40%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .login-form {
    margin-top: 80%;
  }

  .register-form {
    margin-top: 20%;
  }
}


.login-main-text {
  margin-top: 20%;
  padding: 60px;
  color: #fff;
}

.login-main-text h2 {
  font-weight: 300;
}

.btn-black {
  background-color: #000 !important;
  color: #fff !important;
}

.cancel-color {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    height: 100%;
}
.cursor-pointer:hover{
  cursor: pointer;
}
.text-bold{
  font-weight: bold;
}

.display-image {
  position: relative;
  max-width: 300px;
}

.cross-image{
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.text-end{
  text-align: end !important;
}

.serviceBox{
  height: 100px;
  width: 100%;
  background: #ccc;
  font-size:2rem
}
.isActiveServiceBox{
    background: #a00;
    height: 100px;
    width: 100%;
    font-size: 1.1rem
}
.applicationImg {
  max-width: 200px;
  cursor: pointer;
}

.form-heading {
  font-size: 16px !important;
}

/* .logo-image-small {
  width: 200px;
} */

.new-service {
  width: 100px;
  height: 20px;
  background: rgb(38, 185, 195);
}


/*  dashboard is calling */

.iscallingUsers:checked{
  color:"#a00"
}
